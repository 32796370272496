import _ from 'lodash'
import { put } from 'redux-saga/effects'

import buildService from 'common/utils/build-service'
import { changeModal } from 'services/actions'
import { getMyStrategiesService } from 'services/strategies'

export const getAllStrategiesService = buildService({
  type: 'GET',
  constantsPrefix: 'ALL_STRATEGIES',
  isArray: true,
  http: {
    createApiUrlSuffix: (req) => {
      return !_.isUndefined(req) && !_.isUndefined(req.topicId) && req.topicId
        ? `/strategies_for_topic?topic_id=eq.${req.topicId}&order=weight.asc`
        : '/strategies_for_topic?order=weight.asc'
    },
  },
})

export const getStrategyTopicsService = buildService({
  type: 'GET',
  constantsPrefix: 'STRATEGY_TOPICS',
  isArray: true,
  http: {
    createApiUrlSuffix: () => '/topics',
  },
})

export const signupStrategyService = buildService({
  type: 'POST',
  constantsPrefix: 'SIGNUP_STRATEGY',
  http: {
    createApiUrlSuffix: () => '/rpc/strategy_sign_up',
    *successHandler() {
      yield put(getMyStrategiesService.actions.request())
    },
    headers: {
      Prefer: 'params=single-object',
    },
  },
})

export const signoutStrategyService = buildService({
  type: 'POST',
  constantsPrefix: 'SIGNOUT_STRATEGY',
  http: {
    createApiUrlSuffix: () => '/rpc/strategy_sign_out',
    *successHandler() {
      yield put(getAllStrategiesService.actions.request())
      yield put(getMyStrategiesService.actions.request())
      yield put(changeModal({ isOpen: false }))
    },
    headers: {
      Prefer: 'params=single-object',
    },
  },
})

export const completeStrategyService = buildService({
  type: 'POST',
  constantsPrefix: 'COMPLETE_STRATEGY',
  http: {
    createApiUrlSuffix: () => '/rpc/strategy_complete',
    *successHandler() {
      yield put(getMyStrategiesService.actions.request())
    },
    headers: {
      Prefer: 'params=single-object',
    },
  },
})
