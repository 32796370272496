import buildService from 'common/utils/build-service'
import { GET } from 'common/requests'
import { IdOrSlugParam, School } from 'common/types'

export const schoolDetailsService = buildService<IdOrSlugParam, School>({
  type: GET,
  constantsPrefix: 'SCHOOL_DETAILS',
  http: {
    createApiUrlSuffix: ({ slug, id }) => {
      const select =
        'id,name,registration_code,bookmarked,slug,location,school_offline_trainings,city:cities(id,name,state:states(id,abbr,name,country:countries(code,name))),district:districts(id,name,slug),grades:grades!school_grades(id,name,label),phase_id,status,started_ruler_at,started_ruler,total_staff,logo_url,coach_name,coach_email&grades.order=weight.asc'
      if (slug) {
        return `/schools?slug=eq.${slug}&select=${select}`
      }
      if (id) {
        return `/schools?id=eq.${id}&select=${select}`
      }
      throw new Error('Empty slug and id')
    },
    headers: {
      Accept: 'application/vnd.pgrst.object+json',
    },
  },
})

export const getSchoolUserStatisticsService = buildService({
  type: GET,
  constantsPrefix: 'SCHOOL_USER_STATS',
  isArray: true,
  http: {
    createApiUrlSuffix: () => '/schools?select=people_in_school',
  },
})

export const getBookmarkedSchoolsService = buildService({
  type: GET,
  constantsPrefix: 'BOOKMARKED_SCHOOLS',
  isArray: true,
  http: {
    createApiUrlSuffix: () =>
      `/schools?select=id,name,slug,logo_url,bookmarked,district_id,people_in_school,registration_code&bookmarked=is.true&order=name.asc`,
  },
})

export const getSchoolUsersService = buildService<{
  schoolId: string
  onlyImplementationTeam?: boolean
}>({
  type: GET,
  constantsPrefix: 'SCHOOL_USERS',
  isArray: true,
  http: {
    createApiUrlSuffix: ({ schoolId, onlyImplementationTeam }) => {
      if (onlyImplementationTeam) {
        return `/persons?school_id=eq.${schoolId}&select=id,name,email,photo_url,deleted,created_at,role,person_type:person_types(id,name)&role=eq.ruler_school_implementation_team`
      }
      const fields = `id,name,email,role,person_type:person_types(id,name),photo_url,deleted,created_at,school_id,district_id,status`
      const roles = `(role.eq.ruler_school_member,role.eq.ruler_school_admin,role.eq.ruler_school_implementation_team)`
      return `/persons?school_id=eq.${schoolId}&select=${fields}&or=${roles}`
    },
  },
})
