import buildService from 'common/utils/build-service'
import { District, IdOrSlugParam } from 'common/types'

export const getDistrictUsersService = buildService<{ districtId: string }>({
  type: 'GET',
  constantsPrefix: 'DISTRICT_USERS',
  isArray: true,
  http: {
    createApiUrlSuffix: ({ districtId }) => {
      const fields = `id,name,email,role,district_id,person_type:person_types(id,name),status,office_phone,mobile_phone,deleted,created_at,photo_url`
      const roles = `(role.eq.ruler_district_member,role.eq.ruler_district_admin,role.eq.ruler_district_implementation_team)`
      return `/persons?district_id=eq.${districtId}&select=${fields}&or=${roles}`
    },
  },
})

export const getDistrictSchoolsService = buildService({
  type: 'GET',
  constantsPrefix: 'DISTRICT_SCHOOLS',
  isArray: true,
  http: {
    createApiUrlSuffix: ({ districtId }) =>
      `/ext_schools?district_id=eq.${districtId}&select=*,people_in_school&order=name.asc`,
  },
})

export const getDistrictSchoolsListService = buildService({
  type: 'GET',
  constantsPrefix: 'DISTRICT_SCHOOLS_LIST',
  isArray: true,
  http: {
    createApiUrlSuffix: ({ districtId }) =>
      `/schools?district_id=eq.${districtId}&select=id,name&order=name.asc`,
  },
})

export const getBookmarkedDistrictsService = buildService({
  type: 'GET',
  constantsPrefix: 'BOOKMARKED_DISTRICTS',
  isArray: true,
  http: {
    createApiUrlSuffix: () =>
      `/districts?select=id,slug,name,bookmarked,started_ruler,started_ruler_at,registration_code,schools(id)&bookmarked=is.true&order=name.asc`,
  },
})

export const getDistrictDetailsService = buildService<IdOrSlugParam, District>({
  type: 'GET',
  constantsPrefix: 'DISTRICT_DETAILS',
  http: {
    createApiUrlSuffix: ({ slug, id }) => {
      const select =
        'id,name,registration_code,bookmarked,slug,location,district_offline_trainings,city:cities(id,name,state:states(id,abbr,name,country:countries(code,name))),status,started_ruler_at,started_ruler,coach_name,coach_email'
      if (slug) {
        return `/districts?slug=eq.${slug}&select=${select}`
      }
      if (id) {
        return `/districts?id=eq.${id}&select=${select}`
      }
      throw new Error('Empty slug and id')
    },
    headers: {
      Accept: 'application/vnd.pgrst.object+json',
    },
  },
})
